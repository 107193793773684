import { useContext } from 'react';
import CardProject from '../../components/card/cardProject';

import { MarriageContext } from '../../config/context/marriagedCard';

function Collections() {
	const { marriageCardData } = useContext(MarriageContext);
	console.log(marriageCardData);
	return (
		<div className='text-[#3A2414] my-10'>
			<div className='flex flex-col gap-16 my-10'>
				<div className='md:grid grid-cols-3 gap-8 mx-auto'>
					{marriageCardData.length > 0 &&
						marriageCardData?.map((project, i) => (
							<CardProject
								key={i}
								name={project.master_template_card?.name}
								link={`/invitation/${project.master_user?.username}/to/Example`}
								image={project.thumbnailBackgroundImage}
							/>
						))}
					{/* <CardProject
						name={'Project name here'}
						link={'www.example.com'}
						image={'assets/images/porto2.png'}
						width='300px'
					/>
					<CardProject
						name={'Project name here'}
						link={'www.example.com'}
						image={'assets/images/porto2.png'}
						width='300px'
					/>
					<CardProject
						name={'Project name here'}
						link={'www.example.com'}
						image={'assets/images/porto2.png'}
						width='300px'
					/>
					<CardProject
						name={'Project name here'}
						link={'www.example.com'}
						image={'assets/images/porto2.png'}
						width='300px'
					/>
					<CardProject
						name={'Project name here'}
						link={'www.example.com'}
						image={'assets/images/porto3.png'}
						width='300px'
					/>
					<CardProject
						name={'Project name here'}
						link={'www.example.com'}
						image={'assets/images/porto3.png'}
						width='300px'
					/> */}
				</div>
			</div>
		</div>
	);
}

export default Collections;
