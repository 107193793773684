import './scroll.css';

function LeftSection({ isTransitionVisible, isCover, children, ...props }) {
	const overflowClass = isTransitionVisible
		? 'overflow-hidden'
		: 'overflow-y-scroll overflow-x-hidden';

	return (
		<section
			className={`md:basis-[30%] h-screen font-cormorant overflow-y-hidden custom-scrollbar ${overflowClass}`}
			{...props}
		>
			{children}
		</section>
	);
}

export default LeftSection;
