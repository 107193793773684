function Header() {
	return (
		<div className='md:w-full md:h-full pt-32 flex flex-col  items-center justify-center text-[#3A2414] gap-6'>
			<div className='md:w-[768px] flex flex-col justify-center text-center items-center px-6  gap-6'>
				<h1 className='text-4xl md:text-5xl  md:w-full mx-auto font-semibold '>
					Describe what your collection is about
				</h1>
				<p className='text-lg'>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
			</div>
		</div>
	);
}

export default Header;
