import { useContext, useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import { MarriageContext } from '../../config/context/marriagedCard';
import { GlobalContext } from '../../config/context/global';
import { formatDate } from '../../utils/formatDate';

const textVariants = {
	hidden: { opacity: 0, y: -20 },
	visible: { opacity: 1, y: 0 },
};

const calculateTimeLeft = (unixDate) => {
	const difference = unixDate - Date.now();
	let timeLeft = {};

	if (difference > 0) {
		timeLeft = {
			days: Math.floor(difference / (1000 * 60 * 60 * 24)),
			hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
			minutes: Math.floor((difference / 1000 / 60) % 60),
			seconds: Math.floor((difference / 1000) % 60),
		};
	} else {
		return false;
	}

	return timeLeft;
};

function Time() {
	const ref = useRef(null);
	const { marriageCardData } = useContext(MarriageContext);
	const { globalCtx } = useContext(GlobalContext);
	const [isInView, setIsInView] = useState(false);
	const [timeLeft, setTimeLeft] = useState(
		calculateTimeLeft(marriageCardData.unixWeddingDate) || null,
	);
	const { section_countdown } = marriageCardData;

	useEffect(() => {
		const currentRef = ref.current;
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setIsInView(true);
					observer.disconnect(); // Stop observing after the first intersection
				}
			},
			{
				rootMargin: '0px',
				threshold: 0.1,
			},
		);

		if (currentRef) {
			observer.observe(currentRef);
		}

		return () => {
			if (currentRef) {
				observer.unobserve(currentRef);
			}
		};
	}, []);

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeLeft(calculateTimeLeft(marriageCardData.unixWeddingDate));
		}, 1000);

		return () => clearInterval(timer);
	}, [marriageCardData.unixWeddingDate]);

	return (
		<div
			className={`w-full h-screen snap-center`}
			style={{
				backgroundColor: section_countdown.countdownSectionBackgroundColor,
				backgroundImage: section_countdown.isCountdownSectionUsingImage
					? `url(${section_countdown.countdownSectionBackgroundImage})`
					: 'none',
				backgroundSize: 'cover',
				backgroundPosition: 'center',
			}}
		>
			<div className='h-screen p-10 text-white relative top-1/4'>
				<div
					ref={ref}
					className={`h-fit`}
					style={{ color: section_countdown.countdownSectionDescriptionColor }}
				>
					<motion.p
						className='font-bold'
						initial='hidden'
						animate={isInView ? 'visible' : 'hidden'}
						transition={{ duration: 1, delay: 0.4 }}
						variants={textVariants}
					>
						{globalCtx.groomName} & {globalCtx.brideName}
					</motion.p>
					<motion.h1
						className={`text-4xl font-cormorant my-2`}
						style={{ color: section_countdown.countdownSectionTitleColor }}
						initial='hidden'
						animate={isInView ? 'visible' : 'hidden'}
						transition={{ duration: 1, delay: 0.5 }}
						variants={textVariants}
					>
						{section_countdown.countdownSectionTitleText}
					</motion.h1>
					<motion.p
						className='font-light my-5'
						initial='hidden'
						animate={isInView ? 'visible' : 'hidden'}
						transition={{ duration: 1, delay: 0.8 }}
						variants={textVariants}
					>
						{formatDate(marriageCardData.unixWeddingDate)}
					</motion.p>

					<motion.div
						className='flex gap-10 justify-center'
						initial='hidden'
						animate={isInView ? 'visible' : 'hidden'}
						transition={{ duration: 0.8, delay: 1 }}
						variants={textVariants}
					>
						{timeLeft ? (
							Object.entries(timeLeft).map(([unit, value]) => (
								<div
									key={unit}
									className='text-lg font-bold'
								>
									<p className='font-roboto'>{value}</p>
									<p>{unit.charAt(0).toUpperCase() + unit.slice(1)}</p>
								</div>
							))
						) : (
							<h1>Telah Berakhir</h1>
						)}
					</motion.div>
				</div>
			</div>
		</div>
	);
}

export default Time;
