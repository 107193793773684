import { useContext, useEffect, useState } from 'react';
import Btn from '../button/btn';
import CardRfvp from '../card/cardRfvp';
import DropdownInput from '../dropdown/dropdownInput';
import DropdownInputVisitor from '../dropdown/dropdownVisitor';
import { getById, postData } from '../../api';
import { MarriageContext } from '../../config/context/marriagedCard';
import { GlobalContext } from '../../config/context/global';
import { timeAgo } from '../../utils/formatDate';
import './scroll.css';
import Swal from 'sweetalert2';

function Rfvp() {
	const { marriageCardData } = useContext(MarriageContext);
	const { section_rsvp } = marriageCardData;
	const { globalCtx } = useContext(GlobalContext);
	const [messageData, setMessageData] = useState([]);
	const [formMessage, setFormMessage] = useState({
		RSVP: '',
		name: globalCtx.peopleInvitedData.name,
		message: '',
		visitorTotal: '',
		peopleInvitedId: globalCtx.peopleInvitedData.id,
		marriageCardId: marriageCardData.id,
	});

	const handleInputChange = (e) => {
		const { name, value } = e.target;
		setFormMessage((prevFormMessage) => ({
			...prevFormMessage,
			[name]: value,
		}));
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		try {
			const response = await postData({ url: `messages/add`, data: formMessage });
			if (response.statusCode === 201) {
				setFormMessage({
					name: globalCtx.peopleInvitedData.name,
					message: '',
					RSVP: 'yes',
					visitorTotal: '',
					peopleInvitedId: globalCtx.peopleInvitedData.id,
					marriageCardId: globalCtx.peopleInvitedData.marriageCardId,
				});
				const updatedMessageData = await getById({
					url: `messages/list/message/${marriageCardData.id}`,
				});
				setMessageData(updatedMessageData);
			}
		} catch (error) {
			Swal.fire({
				title: 'Sorry!',
				text:
					error.response.data.statusCode === 400 &&
					'Ini adalah undangan sampel. Pengguna tidak dapat menambahkan pesan RSVP.',
				icon: 'error',
				confirmButtonText: 'OK',
			});
			setFormMessage({
				name: globalCtx.peopleInvitedData.name,
				message: '',
				RSVP: 'yes',
				visitorTotal: '',
				peopleInvitedId: globalCtx.peopleInvitedData.id,
				marriageCardId: globalCtx.peopleInvitedData.marriageCardId,
			});
			console.error('Error submitting message:');
		}
	};

	useEffect(() => {
		if (marriageCardData && marriageCardData.id) {
			const fetchMessage = async () => {
				try {
					const initialMessageData = await getById({
						url: `messages/list/message/${marriageCardData.id}`,
					});
					setMessageData(initialMessageData);
				} catch (error) {
					console.error('Error fetching message data:');
				}
			};
			fetchMessage();
		}
	}, [marriageCardData]);
	return (
		<div
			className='w-full h-screen'
			style={{
				backgroundColor: section_rsvp.rsvpSectionBackgroundColor,
				backgroundImage: section_rsvp.isRsvpSectionUsingImage
					? `url(${section_rsvp.rsvpSectionBackgroundImage})`
					: 'none',
				backgroundSize: 'cover',
				backgroundPosition: 'center',
			}}
		>
			<div className='md:px-20 p-10 md:py-10 w-full h-screen relative text-white'>
				<h1
					className='text-start text-3xl'
					style={{ color: section_rsvp.rsvpSectionTitleColor }}
				>
					{section_rsvp.rsvpSectionTitleText}
				</h1>
				<p
					className='text-start text-md my-4'
					style={{ color: section_rsvp.rsvpSectionDescriptionColor }}
				>
					{section_rsvp.rsvpSectionDescriptionText}
				</p>
				<div className='overflow-y-auto pe-4 h-[82vh] custom-scrollbar pb-28'>
					<form onSubmit={handleSubmit}>
						<div>
							<input
								type='text'
								name='name'
								value={formMessage.name}
								onChange={handleInputChange}
								placeholder='Nama'
								required
								className='mt-1 px-5 py-3 border rounded-md shadow-sm block w-full sm:text-sm'
								style={{
									backgroundColor: section_rsvp.rsvpSectionInputColor,
									borderColor: section_rsvp.rsvpSectionInputBorderColor,
									color: section_rsvp.rsvpSectionInputTextColor,
								}}
							/>
						</div>
						<label className='w-1/2'>
							<textarea
								id='message'
								name='message'
								value={formMessage.message}
								onChange={handleInputChange}
								rows='3'
								required
								className='resize-none border rounded-md w-full my-2 py-2 px-5 leading-tight focus:outline-none'
								style={{
									backgroundColor: section_rsvp.rsvpSectionInputColor,
									borderColor: section_rsvp.rsvpSectionInputBorderColor,
									color: section_rsvp.rsvpSectionInputTextColor,
								}}
								placeholder='Ucapan'
							></textarea>
						</label>
						<DropdownInput
							className='border rounded-lg focus:outline-none focus:ring-white focus:border-white'
							style={{
								backgroundColor: section_rsvp.rsvpSectionInputColor,
								borderColor: section_rsvp.rsvpSectionInputBorderColor,
								color: section_rsvp.rsvpSectionInputTextColor,
							}}
							title=''
							value={formMessage.RSVP}
							onChange={(value) => setFormMessage((prev) => ({ ...prev, RSVP: value }))}
						/>
						<DropdownInputVisitor
							title=''
							value={formMessage.visitorTotal}
							className='w-28 my-3'
							onChange={(value) => setFormMessage((prev) => ({ ...prev, visitorTotal: Number(value) }))}
						/>
						<div className='text-start my-4'>
							<Btn
								title='Submit'
								colorBg='bg-gray-500'
								colorText='text-white'
								className='w-24 py-2 font-light rounded-md text-xl'
								type='submit'
							/>
						</div>
					</form>
					<div
						className={`${
							messageData.length > 5 ? 'h-[70vh]' : 'h-fit'
						}  overflow-y-auto p-2 custom-scrollbar`}
					>
						{messageData.map((message, index) => (
							<CardRfvp
								key={index}
								invitedPeople={message.PeopleInvited.attendantName}
								message={message.message}
								date={timeAgo(message.createdAt)}
							/>
						))}
					</div>
				</div>
			</div>
		</div>
	);
}

export default Rfvp;
