import { useEffect, useState, useRef, useContext } from 'react';
import { motion } from 'framer-motion';
import { MarriageContext } from '../../config/context/marriagedCard';

const textVariants = {
	hidden: { opacity: 0, y: -20 },
	visible: { opacity: 1, y: 0 },
};

function Gift() {
	const ref = useRef(null);
	const { marriageCardData } = useContext(MarriageContext);
	const { section_digital_wallet } = marriageCardData;
	const [isInView, setIsInView] = useState(false);
	const items = [
		{
			bank: section_digital_wallet.digitalWalletSectionBankName,
			name: section_digital_wallet.digitalWalletSectionAccountHolder,
			no: section_digital_wallet.digitalWalletSectionRekeningNumber,
		},
	];

	const copyToClipboard = (text) => {
		navigator.clipboard.writeText(text);
		alert('Copied to clipboard: ' + text);
	};

	useEffect(() => {
		const currentRef = ref.current;
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setIsInView(true);
					observer.disconnect(); // Stop observing after the first intersection
				}
			},
			{
				rootMargin: '0px',
				threshold: 0.1,
			},
		);

		if (currentRef) {
			observer.observe(currentRef);
		}

		return () => {
			if (currentRef) {
				observer.unobserve(currentRef);
			}
		};
	}, []);

	return (
		<div
			className='w-full h-screen'
			style={{
				backgroundColor: section_digital_wallet.digitalWalletSectionBackgroundColor,
				backgroundImage: section_digital_wallet.isDigitalWalletSectionUsingImage
					? `url(${section_digital_wallet.digitalWalletSectionBackgroundImage})`
					: 'none',
				backgroundSize: 'cover',
				backgroundPosition: 'center',
				scrollSnapAlign: 'center',
			}}
		>
			<div
				ref={ref}
				className='bg-transparent h-screen p-10 text-white text-center relative top-1/4'
			>
				<div className='w-full h-fit'>
					<motion.h1
						className='font-bold text-4xl my-5 text-start'
						style={{ color: section_digital_wallet.digitalWalletSectionTitleColor }}
						initial='hidden'
						animate={isInView ? 'visible' : 'hidden'}
						transition={{ duration: 0.5, delay: 0.4 }}
						variants={textVariants}
					>
						{section_digital_wallet.digitalWalletSectionTitleText}
					</motion.h1>
					<motion.p
						className='text-sm text-start'
						style={{ color: section_digital_wallet.digitalWalletSectionDescriptionColor }}
						initial='hidden'
						animate={isInView ? 'visible' : 'hidden'}
						transition={{ duration: 0.5, delay: 0.6 }}
						variants={textVariants}
					>
						{section_digital_wallet.digitalWalletSectionDescription}
					</motion.p>
					<motion.div
						className='flex flex-col items-center mt-8 space-y-5'
						initial='hidden'
						animate={isInView ? 'visible' : 'hidden'}
						transition={{ duration: 0.5, delay: 0.8 }}
						variants={textVariants}
					>
						{items.map((item, index) => (
							<div
								key={index}
								className='w-full flex items-center justify-between space-x-1 bg-transparent text-white border-b-2'
							>
								<div
									className='flex flex-col h-fit max-w-40 text-start break-words leading-tight my-2'
									style={{ color: section_digital_wallet.digitalWalletSectionDescriptionColor }}
								>
									<span className='text-sm'>{item.bank} an.</span>
									<span className='w-full -mt-1'>{item.name}</span>
								</div>
								<span
									style={{
										color: section_digital_wallet.digitalWalletSectionTitleColor,
									}}
								>
									{item.no}
								</span>
								<button
									className='px-2 py-1 rounded-lg'
									style={{
										backgroundColor: section_digital_wallet.digitalWalletSectionButtonColor,
										color: section_digital_wallet.digitalWalletSectionButtonTextColor,
									}}
									onClick={() => copyToClipboard(item.no)}
								>
									Copy
								</button>
							</div>
						))}
					</motion.div>
				</div>
			</div>
		</div>
	);
}

export default Gift;
