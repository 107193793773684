import React from 'react';
import Btn from '../../components/button/btn';
import { useNavigate } from 'react-router-dom';

function AboutFooter() {
	const navigate = useNavigate();

	return (
		<div className='section flex items-center justify-center py-24  text-[#624A2D]'>
			<div className='md:flex gap-6'>
				<div className='md:w-1/2'>
					<h2 className='text-start text-4xl md:p-10 font-bold'>Create Your Perfect Invitation Today</h2>
				</div>
				<div className=' flex flex-col items-start justify-start md:w-1/2 pt-10 gap-6'>
					<p className='text-md text-start w-10/12'>
						Elevate your wedding with Love Phoria's luxurious digital invitations. Start designing your
						bespoke invitation now to make your special day unforgettable.
					</p>
					<Btn
						colorBg={'bg-[#B07C92]'}
						title={'Design Your Invitation'}
						colorText={'text-white'}
						className={'px-7 py-4 '}
						onClick={() => navigate('/desain')}
					/>
				</div>
			</div>
		</div>
	);
}

export default AboutFooter;
