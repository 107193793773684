import React, { useEffect, useRef, useState } from 'react';
import { motion } from 'framer-motion';
import Btn from '../button/btnCustom';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const variants = {
	hidden: { opacity: 0, y: -20 },
	visible: { opacity: 1, y: 0 },
};

function Sosmed({
	sosmedName,
	name,
	fatherName,
	description,
	bgImage,
	title,
	isUsingbgImage,
	bgColor,
	btnColor,
	btnTextColor,
	descriptionColor,
	titleColor,
}) {
	const ref = useRef(null);
	const [isInView, setIsInView] = useState(false);

	useEffect(() => {
		const currentRef = ref.current;
		const observer = new IntersectionObserver(
			([entry]) => {
				if (entry.isIntersecting) {
					setIsInView(true);
					observer.disconnect(); // Stop observing after the first intersection
				}
			},
			{
				rootMargin: '0px',
				threshold: 0.1,
			},
		);

		if (currentRef) {
			observer.observe(currentRef);
		}

		return () => {
			if (currentRef) {
				observer.unobserve(currentRef);
			}
		};
	}, []);

	return (
		<div
			className='w-full h-screen'
			style={{
				backgroundColor: bgColor,
				backgroundImage: isUsingbgImage ? `url(${bgImage})` : 'none',
				backgroundSize: 'cover',
				backgroundPosition: 'center',
			}}
		>
			<div
				ref={ref}
				className='h-screen p-10 text-white text-start flex flex-col justify-end'
			>
				<motion.div
					className='h-fit my-28 md:my-10'
					initial='hidden'
					animate={isInView ? 'visible' : 'hidden'}
					variants={variants}
					transition={{ duration: 1, ease: 'easeInOut' }}
				>
					<motion.p
						className='my-3 font-bold'
						style={{ color: titleColor }}
						initial='hidden'
						animate={isInView ? 'visible' : 'hidden'}
						transition={{ duration: 1.5, delay: 1 }}
						variants={variants}
					>
						{title}
					</motion.p>
					<motion.h1
						className='text-5xl font-allura'
						initial='hidden'
						animate={isInView ? 'visible' : 'hidden'}
						transition={{ duration: 1.8, delay: 1 }}
						variants={variants}
					>
						{name}
					</motion.h1>
					<motion.p
						className='text-xl my-3'
						initial='hidden'
						animate={isInView ? 'visible' : 'hidden'}
						transition={{ duration: 1.8, delay: 1 }}
						variants={variants}
					>
						{fatherName}
					</motion.p>
					<motion.p
						className='font-light'
						style={{ color: descriptionColor }}
						initial='hidden'
						animate={isInView ? 'visible' : 'hidden'}
						transition={{ duration: 2, delay: 1 }}
						variants={variants}
					>
						{description ||
							`"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse varius enim in eros elementum tristique. Duis cursus, mi quis viverra ornare, eros dolor interdum nulla, ut commodo diam libero vitae erat."`}
					</motion.p>
					<Btn
						initial='hidden'
						animate={isInView ? 'visible' : 'hidden'}
						transition={{ duration: 2, delay: 1 }}
						variants={variants}
						title={sosmedName}
						colorBg={btnColor}
						colorText={btnTextColor}
						style={{
							backgroundColor: btnColor,
							color: btnTextColor,
						}}
						icon={faInstagram}
						className='w-full text-xl my-5 py-2'
						onClick={() => window.open(`https://www.instagram.com/${sosmedName}`, '_blank')}
					/>
				</motion.div>
			</div>
		</div>
	);
}

export default Sosmed;
