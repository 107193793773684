import { useContext, useEffect, useRef, useState } from 'react';
import { AnimatePresence } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

// components
import Header from '../../components/template2/header';
import LoveStore from '../../components/template2/loveStore';
import Sosmed from '../../components/template2/sosmed';
import Location from '../../components/template2/location';
import Gift from '../../components/template2/gift';
import Gallery from '../../components/template2/gallery';
// import Stream from '../../components/template2/stream';
import Rfvp from '../../components/template2/rfvp';
import Footer from '../../components/template2/footer';
import LeftSection from '../../components/template2/leftSection';
import TransitionIntro from '../../components/template1/transition';
import DreessCodeCardtwo from '../../components/template2/dressCode';
// import ParticlesContainer from '../../components/particles/particlesContainer';

// import { GlobalContext } from '../../config/context/global';
import { MarriageContext } from '../../config/context/marriagedCard';
import { SubscriptionContext } from '../../config/context/subscription';

function TemplateTwo() {
	const [isTransitionVisible, setIsTransitionVisible] = useState(true);
	const handleClose = () => setIsTransitionVisible(false);
	const [showIntro, setShowIntro] = useState(true);
	// const { globalCtx } = useContext(GlobalContext);
	const { marriageCardData } = useContext(MarriageContext);
	const { subscriptionData } = useContext(SubscriptionContext);

	const audioRef = useRef(null);
	const [isPlaying, setIsPlaying] = useState(false);

	const handleAudioToggle = () => {
		if (audioRef.current) {
			if (isPlaying) {
				audioRef.current.pause();
			} else {
				audioRef.current.play().catch((error) => {
					console.error(error);
				});
			}
			setIsPlaying(!isPlaying);
		}
	};

	useEffect(() => {
		const audioElement = audioRef.current;
		audioElement.addEventListener('ended', () => setIsPlaying(false));
		return () => {
			audioElement.removeEventListener('ended', () => setIsPlaying(false));
		};
	}, []);

	return (
		<div className='flex flex-row font-poppins bg-black'>
			<AnimatePresence mode='wait'>
				{showIntro && <TransitionIntro setShowIntro={setShowIntro} />}
			</AnimatePresence>
			<audio
				ref={audioRef}
				src={marriageCardData.weddingMusic || '/assets/audio/audio.mpeg'}
				loop
				muted={marriageCardData.weddingMusic ? false : true}
			/>
			<button
				onClick={handleAudioToggle}
				className='fixed bottom-5 left-5 text-white z-30 bg-transparent text-2xl rounded-lg'
			>
				<FontAwesomeIcon icon={isPlaying ? faPause : faPlay} />
			</button>
			<LeftSection isTransitionVisible={isTransitionVisible}>
				<Header
					isTransitionVisible={isTransitionVisible}
					handleClose={handleClose}
					setIsPlaying={handleAudioToggle}
					setIsTransitionVisible={setIsTransitionVisible}
					isCover={subscriptionData.isUsingCoverSection}
				/>
				{subscriptionData.isUsingBrideSection && (
					<Sosmed
						sosmedName={marriageCardData.section_groom.groomSectionInstagramName}
						name={marriageCardData.section_groom.groomSectionGroomName}
						fatherName={marriageCardData.section_groom.groomSectionFamilyText}
						description={marriageCardData.section_groom.groomSectionDescriptionText}
						bgImage={marriageCardData.section_groom.groomSectionBackgroundImage}
						title={marriageCardData.section_groom.groomSectionTitleText}
						bgColor={marriageCardData.section_groom.groomSectionBackgroundColor}
						btnColor={marriageCardData.section_groom.groomSectionButtonColor}
						btnTextColor={marriageCardData.section_groom.groomSectionButtonTextColor}
						isUsingBgImage={marriageCardData.section_groom.isGroomSectionUsingImage}
						descriptionColor={marriageCardData.section_groom.groomSectionDescriptionColor}
						titleColor={marriageCardData.section_groom.groomSectionTitleColor}
					/>
				)}
				{subscriptionData.isUsingGroomSection && (
					<Sosmed
						sosmedName={marriageCardData.section_bride.brideSectionInstagramName}
						name={marriageCardData.section_bride.brideSectionBrideName}
						fatherName={marriageCardData.section_bride.brideSectionFamilyText}
						description={marriageCardData.section_bride.brideSectionDescriptionText}
						bgImage={marriageCardData.section_bride.brideSectionBackgroundImage}
						title={marriageCardData.section_bride.brideSectionTitleText}
						bgColor={marriageCardData.section_bride.brideSectionBackgroundColor}
						btnColor={marriageCardData.section_bride.brideSectionButtonColor}
						btnTextColor={marriageCardData.section_bride.brideSectionButtonTextColor}
						isUsingBgImage={marriageCardData.section_bride.isBrideSectionUsingImage}
						descriptionColor={marriageCardData.section_bride.brideSectionDescriptionColor}
						titleColor={marriageCardData.section_bride.brideSectionTitleColor}
					/>
				)}
				{subscriptionData.isUsingLoveStorySection && <LoveStore />}
				{subscriptionData.isUsingAgendaSection && <Location />}
				{subscriptionData.isUsingDressCodeSection && <DreessCodeCardtwo />}
				{subscriptionData.isUsingRSVPSection && <Rfvp />}
				{/* {subscriptionData.isUsingGallerySection && <Gallery />} */}
				<Gallery />
				{subscriptionData.isUsingDigitalWalletSection && <Gift />}
				<Footer />
			</LeftSection>
		</div>
	);
}

export default TemplateTwo;
