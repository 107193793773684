import { useContext, useEffect } from 'react';

import Header from '../layout/header';
// import HeroSection from '../layout/header/test';
import Service from '../layout/service';
import Portofolio from '../layout/portofolio';
// import Testimonial from '../layout/testimonial';
import AboutFooter from '../layout/aboutFooter';
import Price from '../layout/price/home';
import Fitur from '../layout/fitur';

import { MarriageContext } from '../config/context/marriagedCard';
import { getAll } from '../api';
import WhatsappButton from '../components/button/btnWa';

function Home() {
	const { marriageCardData, setMarriageCardData } = useContext(MarriageContext);
	useEffect(() => {
		const fetchData = async () => {
			const { data } = await getAll({
				url: `marriage-card/list/active/highlight`,
			});
			// console.log(data);
			setMarriageCardData(data);
		};
		fetchData();
	}, [setMarriageCardData]);
	return (
		<div className='overflow-auto'>
			<Header />
			<Fitur />
			<Service />
			{marriageCardData && <Portofolio />}
			<Price />
			{/* <Testimonial /> */}
			<AboutFooter />
			<WhatsappButton
				phoneNumber={'+6282144346975'}
				message={
					'Halo Lovephoria Invitation! Saya ingin tahu lebih banyak tentang layanan undangan pernikahan digital Anda.'
				}
			/>
		</div>
	);
}

export default Home;
