import React, { useRef } from 'react';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';

// style
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
// import styles from './carousel.module.css';

function Carousel({ dataImages, slide }) {
	const sliderRef = useRef(null);

	const settings = {
		dots: true,
		infinite: true,
		speed: 800,
		slidesToShow: slide || 3,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 3000,
		slidesToScroll: 1,
		initialSlide: 0,
		responsive: [
			{
				breakpoint: 1024,
				settings: {
					slidesToShow: 3,
					slidesToScroll: 3,
					infinite: true,
					dots: true,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 2,
					initialSlide: 2,
				},
			},
			{
				breakpoint: 500,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
		],
	};

	return (
		<div className='slider-container md:h-fit w-full relative'>
			<Slider
				ref={sliderRef}
				{...settings}
				className='flex md:gap-48 h-fit md:h-[45vh]'
			>
				{dataImages.map((imageUrl, index) => (
					<div
						key={index}
						className='p-2 h-[300px] md:h-[43vh]'
					>
						<img
							src={imageUrl}
							alt={`Slide ${index + 1}`}
							className='rounded-2xl h-full object-cover'
						/>
					</div>
				))}
			</Slider>
			<button
				className='absolute top-1/2 transform -translate-y-1/2 left-0 text-3xl text-white p-2'
				onClick={() => sliderRef.current.slickPrev()}
			>
				<FontAwesomeIcon icon={faChevronLeft} />
			</button>
			<button
				className='absolute top-1/2 transform -translate-y-1/2 right-0 text-3xl text-white p-2 '
				onClick={() => sliderRef.current.slickNext()}
			>
				<FontAwesomeIcon icon={faChevronRight} />
			</button>
		</div>
	);
}

export default Carousel;
