import { Route, Routes } from 'react-router-dom';
import Home from '../../pages/home';
import Invitation from '../../pages/invite';
// import About from '../../pages/about';
import Collection from '../../pages/collection';
import Prices from '../../pages/pricing';
import Contact from '../../pages/contactUs';
import PageNotFound from '../../layout/404';
import Policy from '../../pages/policy';
import TermsConditions from '../../pages/TermsConditions';
// import { Example } from '../../pages/example';

export default function Router() {
	return (
		<Routes>
			<Route
				path='/'
				element={<Home />}
			/>
			<Route
				path='/beranda'
				element={<Home />}
			/>
			{/* <Route
				path='/tentang-kami'
				element={<About />}
			/> */}
			<Route
				path='/desain'
				element={<Collection />}
			/>
			{/* <Route
				path='/harga'
				element={<Prices />}
			/> */}
			<Route
				path='/hubungi-kami'
				element={<Contact />}
			/>
			<Route
				path='/privacy-and-policy'
				element={<Policy />}
			/>
			<Route
				path='/term-conditions'
				element={<TermsConditions />}
			/>
			<Route
				path='/invitation/:user?/to/:invitedUser?'
				element={<Invitation />}
			/>
			<Route
				path='*'
				element={<PageNotFound />}
			/>
		</Routes>
	);
}
