import { useParams, useNavigate } from 'react-router-dom';
import { useContext, useEffect, useState } from 'react';
import { GlobalContext } from '../config/context/global';
import { SubscriptionContext } from '../config/context/subscription';
import { TemplateContext } from '../config/context/template';
import { MarriageContext } from '../config/context/marriagedCard';
import { getById } from '../api';

// layout
import TemplateOne from '../layout/template/cardOne';
import TemplateTwo from '../layout/template/cardTwo';
import TemplateThree from '../layout/template/cardThree';

const template1 = process.env.REACT_APP_TEMPLATE_ONE_ID;
const template2 = process.env.REACT_APP_TEMPLATE_TWO_ID;
const template3 = process.env.REACT_APP_TEMPLATE_THREE_ID;

function Invite() {
	const { user, invitedUser } = useParams();
	const navigate = useNavigate();
	const { globalAct } = useContext(GlobalContext);
	const { setSubscriptionData } = useContext(SubscriptionContext);
	const { setTemplateData } = useContext(TemplateContext);
	const { setMarriageCardData } = useContext(MarriageContext);
	const [userData, setUserData] = useState();

	useEffect(() => {
		const fetchData = async () => {
			try {
				const userData = await getById({ url: `marriage-card/${user}/invites/${invitedUser}` });
				const { marriageCard, peopleInvited } = userData;
				setUserData(marriageCard);
				globalAct.setInvitedPeople(invitedUser);
				globalAct.setBrideName(marriageCard.section_bride.brideSectionBrideName);
				globalAct.setGroomName(marriageCard.section_groom.groomSectionGroomName);
				globalAct.setPeopleInvitedData(peopleInvited);
				setSubscriptionData(marriageCard.master_subscription_plan);
				setTemplateData(marriageCard.master_template_card);
				setMarriageCardData(marriageCard);
			} catch (error) {
				if (error.response && error.response.data && error.response.data.statusCode === 404) {
					if (invitedUser !== 'Example') {
						navigate('/404'); // Redirect to the 404 page
					}
				} else {
					console.log('Error fetching', error);
					navigate('/404'); // Redirect to the 404 page
				}
			}
		};
		fetchData();
	}, [invitedUser, user, navigate, setSubscriptionData, setTemplateData, setMarriageCardData]);

	return (
		<div>
			{userData && userData.master_template_card._id === template1 && <TemplateOne />}
			{userData && userData.master_template_card._id === template2 && <TemplateTwo />}
			{userData && userData.master_template_card._id === template3 && <TemplateThree />}
		</div>
	);
}

export default Invite;
